import { format as formatDate } from "date-fns";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import slugify from "slugify";

import ClubAddress from "@/components/ClubAddress";
import ErrorHandler from "@/components/ErrorHandler";
import HolidayHours from "@/components/HolidayHours";
import Accordion from "@/components/accordion";
import SecondaryHeadline from "@/components/headlines/club-detail/secondary";
import { groupHolidayHours } from "@/utils/helpers/holidays";
import Helpers from "@/utils/helpers/response_handlers";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./index.module.scss";

const CreateHoursAccordion = ({
  data,
  holidays,
  displayHolidays,
  accordionID,
  label,
  selectedAccordion,
  selectAccordionFunc,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      label={label}
      selectAccordionFunc={selectAccordionFunc}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((day) => {
          const nameFormatted =
            day.name.charAt(0).toUpperCase() + day.name.slice(1);

          if (day.isClosed) {
            return (
              <li alt={day.name} key={day.name} title={day.name}>
                <label>{nameFormatted}</label>
                <time>Closed</time>
              </li>
            );
          }

          return (
            <li alt={day.name} key={day.name} title={day.name}>
              <label>{nameFormatted}</label>
              <time>
                {day.hours.map((hour) => (
                  <React.Fragment key={`${hour.open}-${hour.close}`}>
                    {hour.open} &mdash; {hour.close}
                    <br />
                  </React.Fragment>
                ))}
              </time>
            </li>
          );
        })}
      </ul>
      {displayHolidays && holidays && (
        <div className={styles.holidayException}>
          {holidays &&
            holidays.map((group) => (
              <HolidayHours data={group} key={group?.name} />
            ))}
        </div>
      )}
    </Accordion>
  );
};

CreateHoursAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  displayHolidays: PropTypes.bool,
  holidays: PropTypes.array,
  label: PropTypes.string.isRequired,
  selectAccordionFunc: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

const CreateAmenitiesAccordion = ({
  data,
  accordionID,
  label,
  selectedAccordion,
  selectAccordionFunc,
  className,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      className={className}
      label={label}
      selectAccordionFunc={selectAccordionFunc}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((amenity) => {
          return <li key={amenity.title}>{amenity.title}</li>;
        })}
      </ul>
    </Accordion>
  );
};

CreateAmenitiesAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  selectAccordionFunc: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

const CreateSecondaryHoursAccordion = ({
  data,
  accordionID,
  label,
  selectedAccordion,
  selectAccordionFunc,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      label={label}
      selectAccordionFunc={selectAccordionFunc}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((day) => {
          const item = day.fields;
          let isClosed = item.storeOpen;
          let openString, closeString;

          if (!item.timeOpen || !item.timeClose) {
            isClosed = true;
          } else {
            try {
              const now = new Date();
              const open =
                formatDate(now, "yyyy-MM-dd") +
                "T" +
                (item?.timeOpen?.trim()?.length === 4 ? "0" : "") +
                item.timeOpen;
              const close =
                formatDate(now, "yyyy-MM-dd") +
                "T" +
                (item?.timeClose?.trim()?.length === 4 ? "0" : "") +
                item.timeClose;
              openString = formatDate(open, "h:mma");
              closeString = formatDate(close, "h:mma");
            } catch (e) {
              console.error(e);
            }
          }

          return (
            <li key={item.day}>
              <label>{item.day}</label>

              {openString && closeString && isClosed ? (
                <time>
                  {openString} &mdash; {closeString}
                </time>
              ) : (
                <span>closed</span>
              )}
            </li>
          );
        })}

        <span className={styles.note}>*Hours subject to change</span>
      </ul>
    </Accordion>
  );
};

CreateSecondaryHoursAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  selectAccordionFunc: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

export class Accordion2Column extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPresale: false,
      selectedAccordion: "club-hours",
    };

    this.selectAccordionFunc = this.selectAccordionFunc.bind(this);
  }

  selectAccordionFunc(accordionID) {
    const { selectedAccordion } = this.state;

    if (selectedAccordion === accordionID) {
      return this.setState({ selectedAccordion: null });
    }

    this.setState({ selectedAccordion: accordionID });
  }
  componentDidMount() {
    const { facility } = this.props;

    if (facility) {
      this.setState({ isPresale: facility.isPresale });
    }
  }

  render() {
    try {
      const { facility, data, isDesktop } = this.props;
      const { selectedAccordion, isPresale } = this.state;

      const { subNavLabel, floorPlanSquareFeet } = data;
      const comingSoonFloorPlanImage =
        data.comingSoonFloorPlanImage &&
        data.comingSoonFloorPlanImage.fields.file.url;
      const comingSoonFloorPlanImageMobile =
        data.comingSoonFloorPlanImageMobile &&
        data.comingSoonFloorPlanImageMobile.fields.file.url;

      const sectionSlug = slugify(subNavLabel).toLowerCase();

      const {
        contactInformation,
        serviceHours,
        kidsClubServiceHours,
        spaServiceHours,
        holidays,
      } = facility;

      const { refreshmentStore } = data;
      const secondaryStoreHours = get(refreshmentStore, "fields", null);
      const amenitiesArray = get(facility, "amenities", []);
      const clubSchedule = Helpers.convertFacilityApiLocationHours(
        serviceHours,
        "h:mma"
      );
      const kidsClubSchdule = Helpers.convertFacilityApiLocationHours(
        kidsClubServiceHours,
        "h:mma"
      );
      const spaSchedule = Helpers.convertFacilityApiLocationHours(
        spaServiceHours,
        "h:mma"
      );
      const clubGeneralManager = facility.managers.find(
        (manager) => manager.title === "General Manager"
      );
      const sectionTweenFrom = isDesktop
        ? {
            css: {
              opacity: 0,
              top: "150px",
            },
            ease: "Strong.easeInOut",
          }
        : {
            css: {
              opacity: 1,
              top: "0px",
            },
            ease: "Strong.easeInOut",
          };
      const sectionTweenTo = {
        css: {
          opacity: 1,
          top: "0px",
        },
        ease: "Strong.easeInOut",
      };
      const groupedHolidays = groupHolidayHours(holidays);

      return (
        <section
          className={`${styles.Accordion2ColumnContainer} module container`}
          id={sectionSlug}
        >
          <Controller>
            <Scene
              duration="20%"
              key={sectionSlug}
              triggerElement={`#${sectionSlug}`}
            >
              {(progress) => {
                return (
                  <Tween
                    from={sectionTweenFrom}
                    paused
                    to={sectionTweenTo}
                    totalProgress={progress}
                  >
                    <div
                      className={`${styles.inner} row justify-content-between no-gutters`}
                    >
                      <div
                        className={`${styles.clubDataContainer} col-sm-16 col-md-16 col-lg-4`}
                      >
                        <SecondaryHeadline text={`Club Info`} />
                        {contactInformation && (
                          <div className={styles.clubAddress}>
                            <ClubAddress
                              contactInformation={contactInformation}
                              label="Club Address"
                            />
                          </div>
                        )}
                        {clubGeneralManager && (
                          <div className={styles.clubManager}>
                            {clubGeneralManager.title}
                            <br />
                            {clubGeneralManager.formattedName}
                          </div>
                        )}
                      </div>
                      {!isPresale ? (
                        <>
                          <div
                            className={`${styles.accordionContainer} col-sm-16 col-md-7 col-lg-5`}
                          >
                            {clubSchedule && (
                              <CreateHoursAccordion
                                accordionID="club-hours"
                                data={clubSchedule}
                                displayHolidays={true}
                                holidays={groupedHolidays}
                                label="Club Hours"
                                selectAccordionFunc={this.selectAccordionFunc}
                                selectedAccordion={selectedAccordion}
                              />
                            )}
                            {kidsClubSchdule && (
                              <CreateHoursAccordion
                                accordionID="kids-club"
                                data={kidsClubSchdule}
                                holidays={groupedHolidays}
                                label="Kids Club Hours"
                                selectAccordionFunc={this.selectAccordionFunc}
                                selectedAccordion={selectedAccordion}
                              />
                            )}
                            {spaSchedule && (
                              <CreateHoursAccordion
                                accordionID="spa-schedule"
                                data={spaSchedule}
                                holidays={groupedHolidays}
                                label="Spa Hours"
                                selectAccordionFunc={this.selectAccordionFunc}
                                selectedAccordion={selectedAccordion}
                              />
                            )}
                            {secondaryStoreHours && (
                              <CreateSecondaryHoursAccordion
                                accordionID="secondary-store-schedule"
                                data={secondaryStoreHours.hours}
                                label={`${secondaryStoreHours.label} Hours`}
                                selectAccordionFunc={this.selectAccordionFunc}
                                selectedAccordion={selectedAccordion}
                              />
                            )}
                            {amenitiesArray.length > 0 && (
                              <CreateAmenitiesAccordion
                                accordionID="club-amenities"
                                className={styles.amenityMobileAccordion}
                                data={amenitiesArray}
                                label="Amenities"
                                selectAccordionFunc={this.selectAccordionFunc}
                                selectedAccordion={selectedAccordion}
                              />
                            )}
                          </div>
                          <div
                            className={`${styles.amenitiesContainer} col-sm-16 col-md-7 col-lg-5`}
                          >
                            {amenitiesArray.length > 0 && (
                              <div className={styles.amenities}>
                                <h3
                                  className={styles.amenitiesLabel}
                                  id="amenitiesHeader"
                                >
                                  Amenities
                                </h3>
                                <ul aria-labelledby="amenitiesHeader">
                                  {amenitiesArray.map((amenity) => {
                                    return (
                                      <li key={amenity.title}>
                                        {amenity.title}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className={`${styles.floorPlanContainer} col-lg-11`}
                        >
                          <div className={styles.floorPlanContainerUpper}>
                            <h3>
                              Floor Plan{" "}
                              <span>{floorPlanSquareFeet} sq ft</span>
                            </h3>
                          </div>
                          <div
                            className={`${styles.floorPlanContainerLower} d-lg-inline-block d-md-none d-sm-none d-xs-none`}
                          >
                            <img
                              alt="floor plan"
                              src={comingSoonFloorPlanImage}
                            />
                          </div>
                          <div
                            className={`${styles.floorPlanContainerLower} d-xl-none d-lg-none d-md-inline-block`}
                          >
                            <img
                              alt="floor plan"
                              src={comingSoonFloorPlanImageMobile}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Tween>
                );
              }}
            </Scene>
          </Controller>
        </section>
      );
    } catch (error) {
      return <ErrorHandler componentName="Accordion2Column" error={error} />;
    }
  }
}

Accordion2Column.propTypes = {
  data: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool,
};

export default ScreenDimensions(Accordion2Column);

export const groupHolidayHours = (holidays) => {
  const groupedHolidays = [];

  holidays.forEach((h) => {
    if (groupedHolidays.length === 0) {
      // First Holiday of the Array, we push it.
      groupedHolidays.push(h);
    } else {
      // Not the First Holiday in the array
      const index = groupedHolidays.findIndex((gh) => gh.name === h.name);

      if (index === -1) {
        // It does not exist in the array, we push it.
        groupedHolidays.push(h);
      } else {
        // There`s already an objet with the very same name
        if (groupedHolidays[index].holidayHours.length === 0) {
          // If for some unknown reason, not sure if possible, the Holiday Hours array is an empty array
          groupedHolidays[index].holidayHours = h.holidayHours;
        } else {
          // The array already contains data, we check whether or not it`s the same holiday repeated :shrug:
          h.holidayHours.forEach((holiday) => {
            const holidayIndex = groupedHolidays[index].holidayHours.findIndex(
              (aeh) =>
                holiday.startTime === aeh.startTime &&
                holiday.endTime === aeh.endTime
            );

            if (holidayIndex === -1) {
              // Holiday does not exist, we include it
              groupedHolidays[index].holidayHours = [
                ...groupedHolidays[index].holidayHours,
                holiday,
              ];
            }
          });
        }
      }
    }
  });

  return groupedHolidays;
};

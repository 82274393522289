import PropTypes from "prop-types";
import React, { useEffect } from "react";

/***
 * TODO: Should we use idiomatic React instead? e.g.: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * Or a library? e.g.: https://github.com/bvaughn/react-error-boundary
 */
const ErrorHandler = ({ componentName, error }) => {
  useEffect(() => {
    console.error(
      `Error: Module '${componentName}' on page '${window?.location?.pathname}' has thrown the following error and has been supressed: \n${error}`
    );
  }, [componentName, error]);

  return <div data-is-error="true" />;
};

ErrorHandler.propTypes = {
  componentName: PropTypes.string.isRequired,
  error: PropTypes.instanceOf(Error).isRequired,
};

export default ErrorHandler;

import { addDays, differenceInDays, format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import styles from "./HolidayHours.module.scss";

const HolidayHours = ({ data }) => {
  const days = [];

  data?.holidayHours.forEach(({ endDay, endTime, startDay, startTime }) => {
    const isClosed = startTime.split("T")[1] === endTime.split("T")[1];
    const totalDays =
      differenceInDays(new Date(endDay), new Date(startDay)) + 1;
    const isMultiday = totalDays > 1;

    const startTimeFormatted = formatDate(
      new Date(startTime),
      "h:mma"
    ).toLowerCase();
    const endTimeFormatted = formatDate(
      new Date(endTime),
      "h:mma"
    ).toLowerCase();

    if (isMultiday) {
      for (let x = 0; x < totalDays; x++) {
        const startTimeDayName = formatDate(
          addDays(new Date(startTime), x),
          "MMM dd"
        ).toLowerCase();

        days.push({
          endTimeFormatted,
          isClosed,
          name: startTimeDayName,
          startTimeFormatted,
        });
      }
    } else {
      const startTimeDayName = formatDate(
        new Date(startTime),
        "MMM dd"
      ).toLowerCase();

      days.push({
        endTimeFormatted,
        isClosed,
        name: startTimeDayName,
        startTimeFormatted,
      });
    }
  });

  return (
    <figure className={styles.holidayHours} data-is="HolidayHours">
      <figcaption className={styles.title}>
        {data?.name?.toLowerCase()}
      </figcaption>

      <dl className={styles.hours}>
        {days?.map((day) => (
          <React.Fragment key={day.name}>
            <dt className={styles.day}>{day.name}</dt>

            <dd className={styles.times}>
              {day.isClosed ? (
                "Closed"
              ) : (
                <>
                  <time>{day.startTimeFormatted}</time>&nbsp;-&nbsp;
                  <time>{day.endTimeFormatted}</time>
                </>
              )}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </figure>
  );
};

HolidayHours.propTypes = {
  data: PropTypes.object,
};

export default HolidayHours;

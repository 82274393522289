import PropTypes from "prop-types";
import React from "react";

import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./ClubAddress.module.scss";

const ClubAddress = ({
  label,
  isPresale,
  contactInformation,
  isDesktop,
  clubType,
}) => {
  const whiteAddress = clubType !== "special";
  const addressColor = whiteAddress ? styles.whiteAddress : styles.grayAddress;

  return (
    <address aria-label={label} className={`${styles.address} ${addressColor}`}>
      {isPresale && (
        <>
          <label>{label}</label>
          <br />
        </>
      )}
      <a
        className={styles.clubAddress}
        {...(isDesktop
          ? null
          : {
              href: `http://maps.google.com/?q=${contactInformation.address}${" "}${contactInformation.city},${contactInformation.state}${" "}${contactInformation.zip}`,
            })}
      >
        <span>{contactInformation.address}</span>
        <br />
        <span>
          {contactInformation.city}, {contactInformation.state}{" "}
          {contactInformation.zip}
        </span>
      </a>
      <br />
      <a
        {...(isDesktop
          ? null
          : { href: `tel:${contactInformation.phoneNumber}` })}
      >
        <span>
          {contactInformation.phoneNumber !== "(000) 000-0000" &&
            contactInformation.phoneNumber}
        </span>
      </a>
    </address>
  );
};

ClubAddress.propTypes = {
  classAddress: PropTypes.string,
  clubType: PropTypes.string,
  contactInformation: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool,
  isPresale: PropTypes.bool,
  label: PropTypes.string,
};

export default withScreenDimensions(ClubAddress);

import PropTypes from "prop-types";
import React from "react";

import Utilities from "@/utils/helpers/formatting";

import styles from "./index.module.scss";

const SecondaryHeadline = ({ text, count = null, description }) => {
  return (
    <>
      <h3 className={styles.secondaryHeadline}>
        {count && <span>{count}</span>}
        {` `}
        {Utilities.renderMultiLine(text)}
      </h3>
      {description && (
        <p className={`${styles.secondaryDescription}`}>
          {Utilities.renderMultiLine(description)}
        </p>
      )}
    </>
  );
};

SecondaryHeadline.propTypes = {
  description: "",
};

SecondaryHeadline.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default SecondaryHeadline;
